<template>
  <div class="side-container">
    <h2 class="title">{{ title }}</h2>
    <ul
      class="side-content"
      v-if="classOneList.length > 0"
      @scroll.passive="getScroll($event)"
    >
      <li
        v-for="item in classOneList"
        :key="item.id"
        class="side-li"
        @mouseenter="handleEnter(item)"
      >
        <div class="left">
          <span class="content">{{ item.subjectName }}</span>
          <span class="icon" v-if="otherList.length > 0">&gt;</span>
        </div>
        <div
          class="right"
          v-if="otherList.length > 0"
          ref="test"
          :style="{ marginTop: -44 - topSize + 'px' }"
        >
          <span
            v-for="(v, i) in otherList"
            :key="v.id"
            @click="subClick(v, i, item)"
            :class="{ active: index === i }"
            >{{ v.subjectName }}</span
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      otherList: [],
      classOneList: [],
      topSize: 0,
      index: -1,
      choose: {},
      chooseItem: "",
      chooseIndex: "",
    };
  },
  created() {
    this.getOneList();
  },
  methods: {
    getScroll(event) {
      this.topSize = 0;
      this.topSize = this.topSize + event.target.scrollTop;
    },
    subClick(v, i, item) {
      this.chooseIndex = i;
      this.chooseItem = item;
      if (v.subjectName === this.choose.subjectName) {
        this.index = -1;
        this.choose = {};
        this.chooseIndex = -1;
        this.$emit("requestAll", v);
      } else {
        this.index = i;
        this.choose = v;
        this.$emit("subClick", v, item);
      }
    },
    async getTwoList(id) {
      const resp = await this.$apis.autherServe.getTwoList({
        prevSubjectId: id,
      });
      this.otherList = resp.researchTwoList;
    },
    handleEnter(item) {
      if (this.chooseItem.id === item.id) {
        this.index = this.chooseIndex;
      } else {
        this.index = -1;
      }
      this.getTwoList(item.id);
    },
    // 分类
    async getOneList() {
      const resp = await this.$apis.autherServe.getOneList();
      this.classOneList = resp.researchOneList;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-content::-webkit-scrollbar {
  width: 4px;
}
.side-content::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 10px;
  -webkit-box-shadow-: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.side-content::-webkit-scrollbar-track {
  -webkit-box-shadow-: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.side-container {
  width: 100%;
  height: 452px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #ffffff;
  margin-bottom: 9px;
  padding-bottom: 38px;
  user-select: none;
  border: 1px dotted #999999;
  border-radius: 6px;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    margin: 27px 0px 0 30px;
  }
  .side-content {
    margin-top: 13px;
    height: 83%;
    overflow-y: scroll;
    .side-li {
      height: 44px;
      line-height: 44px;
      cursor: pointer;
      padding: 0 4px;
      .right {
        width: 814px;
        padding: 13px 20px 16px 19px;
        border: 1px solid #00a4ff;
        border-radius: 0px 16px 16px 16px;
        background-color: #f9f9f9;
        margin: -44px 0 0 254px;
        box-shadow: 0px 2px 6px 1px rgba(8, 10, 58, 0.23);
        position: absolute;
        display: none;
        z-index: 9;
        left: 0;
        span {
          font-size: 16px;
          color: #333333;
          &.active {
            font-size: 16px;
            color: #00a4ff;
          }
          &::after {
            content: "|";
            display: inline-block;
            margin: 0 3px;
            display: none;
          }
          &:not(:last-child) {
            &::after {
              display: inline-block;
            }
          }
        }
        li {
          display: inline-block;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: -15px;
          top: 0;
          width: 30px;
          height: 44px;
        }
      }
      &:hover {
        border-right: none;
        background: #00a4ff;
        color: #fff;
        .left {
          border: none;
        }
        .right {
          display: block;
        }
      }
      .left {
        height: 100%;
        margin: 0 30px;
        display: flex;
      }
      .content {
        display: inline-block;
        width: 150px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        margin-right: 60px;
        color: inherit;
      }
      .icon {
        opacity: 0.26;
      }
    }
    .last {
      height: 44px;
      line-height: 44px;
      cursor: pointer;
      margin: 0 30px;
      border-bottom: none;
    }
  }
}
</style>
