<template>
  <div class="more-author-container">
    <div class="side">
      <HundredSubject
        title="研究领域"
        @subClick="handleSub"
        @requestAll="handleAll"
      ></HundredSubject>
      <SideBox
        style="margin-top: 40px"
        title="学者头衔"
        :sideList="honourList"
        @requestAll="handleAll1"
        @requestClick="requestClick"
      ></SideBox>
    </div>
    <div style="position: relative;">
      <div class="container">
        <Block
          class="block"
          v-for="(item, i) in listData.list"
          :key="i"
          :block="item"
        ></Block>
      </div>
      <div v-show="listData.total == 0" class="no-student">
        <img :src="require('@/assets/qietu/results.png')" alt="">
        <p>暂无学者</p>
      </div>
      <Page
        v-show="+listData.total > 20"
        :total="+listData.total"
        :current="listData.pageNum"
        show-total
        :page-size="20"
        :styles="{ textAlign: 'center', marginTop: '20px' }"
        @on-change="pageChange"
      >
      </Page>
      <Spin size="large" fix v-show="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
import SideBox from "@/components/pager/SideBox.vue";
import HundredSubject from "@/components/pager/HundredSubject.vue";
import Block from "../components/Block.vue";
export default {
  components: {
    Block,
    SideBox,
    HundredSubject,
  },
  data() {
    return {
      listData: {},
      iconList: [
        require("@/assets/imgs/icon-sch_02.png"),
        require("@/assets/imgs/icon-sch_03.png"),
        require("@/assets/imgs/icon-sch_04.png"),
        require("@/assets/imgs/icon-sch_05.png"),
        require("@/assets/imgs/icon-sch_06.png"),
        require("@/assets/imgs/icon-sch_07.png"),
        require("@/assets/imgs/icon-sch_08.png"),
        require("@/assets/imgs/icon-sch_09.png"),
      ],
      honourList: [],
      params: {
        pageNo: 1,
        pageSize: 20,
      },
      spinShow: false,
    };
  },
  created() {
    this.getScholarList(this.params);
    this.getHonourList();
  },
  methods: {
    pageChange(page) {
      this.getScholarList({
        ...this.params,
        pageNo: page,
      });
    },
    async getScholarList(data) {
      this.spinShow = true;
      const resp = await this.$apis.autherServe.getScholarList(data);
      this.listData = resp.data;
      this.spinShow = false;
    },
    async getHonourList() {
      const resp = await this.$apis.autherServe.getHonourList();
      this.honourList = resp.data;
    },
    handleAll() {
      delete this.params.subjectOne;
      delete this.params.subjectTwo;
      // 请求全部
      this.getScholarList(this.params);
    },
    handleAll1() {
      delete this.params.honourId;
      // 请求全部
      this.getScholarList(this.params);
    },
    handleSub(v, item) {
      this.params.subjectOne = item.id;
      this.params.subjectTwo = v.id;
      this.getScholarList({
        ...this.params,
      });
    },
    requestClick(item) {
      this.params.honourId = item.id;
      this.getScholarList({
        ...this.params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.more-author-container {
  width: 1440px;
  margin: 70px auto;
  display: flex;
  .container {
    width: 1160px;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    .block {
      width: 264px;
      margin: 0 28px 28px 0;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .no-student {
    padding-top: 50px;
    text-align: center;
    font-size: 16px;
    color: #999999;
    img {
      width: 483px;
      height: 304px;
    }
  }
  .side {
    width: 255px;
    margin-right: 40px;
  }
}
</style>
