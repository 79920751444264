<template>
  <div class="side-box-container">
    <h2 class="title">{{ title }}</h2>
    <ul v-if="title === '研究领域'">
      <li
        v-for="(item, i) in sideList"
        :key="i"
        :class="{ active: i === index }"
      >
        <span>{{ item.title }}</span
        ><span>&gt;</span>
      </li>
      <div class="down"></div>
    </ul>
    <ul v-else-if="title === '分类'">
      <li
        v-for="(item, i) in sideList"
        :key="i"
        ref="sideLi"
        :class="{ active: i === index }"
        @click="handleHonour(item, i)"
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <ul v-else>
      <li
        v-for="(item, i) in sideList"
        ref="sideLi"
        :key="i"
        :class="{ active: i === index }"
        @click="handleHonour(item, i)"
      >
        <span>{{ item.honourName }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sideList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      index: -1,
    };
  },
  methods: {
    handleHonour(item, i) {
      this.index = i;
      let element = this.$refs.sideLi[this.index];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        this.$emit("requestAll");
      } else {
        element.classList.add("active");
        this.$emit("requestClick", item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-box-container {
  width: 100%;
  height: 452px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  margin-bottom: 40px;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    margin: 43px 0 0 30px;
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    margin-bottom: 16px;
  }
  ul {
    // height: 298px;
    overflow: hidden;
    margin-bottom: 30px;
    li {
      height: 41px;
      line-height: 41px;
      display: flex;
      justify-content: space-between;
      padding: 0 28px;
      cursor: pointer;
      &.active {
        background-color: #00a4ff;
        color: #fff;
      }
    }
  }
  .down {
    width: 20px;
    height: 20px;
    border-right: 1px solid #00a4ff;
    border-bottom: 1px solid #00a4ff;
    margin: -30px auto;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
</style>
